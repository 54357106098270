body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

code {
  font-family: 'Poppins', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes fadeIn {
  0% { opacity:0; }
  100% { opacity:1; }
}

@keyframes riseUp {
  from { top: 30px; }
  to { top: 0px; }
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes riseLeft {
  from { left: 100px; }
  to { left: 0px; }
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes riseRight {
  from { right: 100px; }
  to { right: 0px; }
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes scaleX {
  0% { width: 100%; }
  100% { width: 0%; }
}